<template>
  <div>
    <label>Aplicação</label>
    <b-form-select
      :disabled="disabled"
      :options="selectOptions"
      :value="value"
      @change="change_value($event)"
    ></b-form-select>
  </div>
</template>

<script>
export default {
  props: ["value", "disabled"],
  computed: {
    state(){
      return this.$store.state.global
    },
    applicationsById() {
      return _.keyBy(this.state.applications, "id");
    },
    selectOptions() {
      const first = {
        value: "",
        text: "Selecione a aplicação",
        disabled: true,
      };
      const second = { value: null, text: "Todas as aplicações" };
      const applications = _.map(
        this.state.unityApplications,
        (channel) => {
          return {
            text: this.applicationsById[channel.application_id].name,
            value: channel.application_id,
          };
        }
      );
      applications.unshift(second);
      applications.unshift(first);

      return applications;
    },
  },
  methods: {
    change_value(v) {
      this.$emit("input", v);
    },
  },
};
</script>

<style>
</style>